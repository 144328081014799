import {
  AddBoldCircleIcon,
  AmericanExpressSvg,
  ApplePaySvg,
  ArrowDownBoldIcon,
  ArrowRightBoldIcon,
  ArrowRightIcon,
  ArrowUpBoldIcon,
  BoxBoldIcon,
  BoxTickBoldIcon,
  BoxTimeBoldIcon,
  BuildingBoldIcon,
  BuildingIcon,
  Calendar2BoldIcon,
  CalendarBoldIcon,
  CalendarTickBoldIcon,
  CardRemoveBoldIcon,
  CardRemoveIcon,
  CardTrickBoldIcon,
  CardTrickIcon,
  ChevronRight,
  ClockBoldIcon,
  CloseCircle2Icon,
  CloseCircleIcon,
  CopyIcon,
  DangerBoldIcon,
  DangerIcon,
  DeleteBoldIcon,
  DiscoverSvg,
  DocumentTextBoldIcon,
  DocumentTextIcon,
  DollarCirleIcon,
  Edit2BoldIcon,
  Edit2Icon,
  EditBoldIcon,
  ExportBoldIcon,
  Eye,
  EyeBoldIcon,
  EyeSlash,
  FacebookLight,
  ForwardSquareBoldIcon,
  GoogleLight,
  House2BoldIcon,
  House2Icon,
  House2miniIcon,
  InfoCircle,
  InfoCircleBoldIcon,
  JcbSvg,
  KeyIcon,
  KeySquareBoldIcon,
  Loader,
  LocationIcon,
  LockCircleBoldIcon,
  MaestroSvg,
  MasterCardSvg,
  MoneyReciveIcon,
  MoneyRemoveIcon,
  MoneyTimeIcon,
  Note2BoldIcon,
  NoteTextBoldIcon,
  OtherSvg,
  PaypalSvg,
  PPColorIcon,
  Profile2UserIcon,
  ProfileCirleIcon,
  ReceiptTextIcon,
  RefreshBoldIcon,
  SearchIcon,
  SmsBoldIcon,
  SmsIcon,
  TickCircle,
  TickCirleIcon,
  TicketExpiredBulkIcon,
  TrashIcon,
  UnionPaySvg,
  User2Icon,
  UserRemoveIcon,
  VerifyBoldIcon,
  VisaSvg,
  WalletCheckIcon,
  TagLineIcon,
  TagBoldIcon,
} from "./SvgIcon";

const Icon = {
  Location: LocationIcon,
  InfoCircle: InfoCircle,
  ArrowRight: ArrowRightIcon,
  ChevronRight: ChevronRight,
  Loader: Loader,
  Eye: Eye,
  EyeSlash: EyeSlash,
  GoogleLight: GoogleLight,
  FacebookLight: FacebookLight,
  TickCircle: TickCircle,
  SmsBoldIcon: SmsBoldIcon,
  InfoCircleBoldIcon: InfoCircleBoldIcon,
  DangerBoldIcon: DangerBoldIcon,
  CloseCircleIcon: CloseCircleIcon,
  KeyIcon: KeyIcon,
  SmsIcon: SmsIcon,
  ArrowRightBoldIcon: ArrowRightBoldIcon,
  ArrowDownBoldIcon: ArrowDownBoldIcon,
  SearchIcon: SearchIcon,
  Edit2Icon:Edit2Icon,
  Edit2BoldIcon:Edit2BoldIcon,
  TrashIcon:TrashIcon,
  AddBoldCircleIcon: AddBoldCircleIcon,
  ArrowUpBoldIcon: ArrowUpBoldIcon,
  User2Icon: User2Icon,
  DeleteBoldIcon: DeleteBoldIcon,
  EditBoldIcon: EditBoldIcon,
  EyeBoldIcon: EyeBoldIcon,
  ProfileCirleIcon: ProfileCirleIcon,
  Profile2UserIcon: Profile2UserIcon,
  CardTrickIcon: CardTrickIcon,
  CardRemoveIcon: CardRemoveIcon,
  TickCirleIcon: TickCirleIcon,
  DangerIcon: DangerIcon,
  KeySquareBoldIcon: KeySquareBoldIcon,
  CopyIcon: CopyIcon,
  House2miniIcon: House2miniIcon,
  UserRemoveIcon:UserRemoveIcon,
  DollarCirleIcon:DollarCirleIcon,
  ReceiptTextIcon:ReceiptTextIcon,
  WalletCheckIcon:WalletCheckIcon,
  MoneyTimeIcon:MoneyTimeIcon,
  MoneyRemoveIcon:MoneyRemoveIcon,
  CalendarBoldIcon:CalendarBoldIcon,
  DocumentTextIcon:DocumentTextIcon,
  ExportBoldIcon:ExportBoldIcon,
  CloseCircle2Icon:CloseCircle2Icon,
  CardTrickBoldIcon:CardTrickBoldIcon,
  Calendar2BoldIcon:Calendar2BoldIcon,
  paypal: PaypalSvg,
  "paypal-mini": PPColorIcon,
  "american-express": AmericanExpressSvg,
  applepay: ApplePaySvg,
  discover: DiscoverSvg,
  jcb: JcbSvg,
  maestro: MaestroSvg,
  other: OtherSvg,
  unionpay: UnionPaySvg,
  visa: VisaSvg,
  mastercard: MasterCardSvg,
  "free": TicketExpiredBulkIcon,
  ClockBoldIcon:ClockBoldIcon,
  RefreshBoldIcon:RefreshBoldIcon,
  DocumentTextBoldIcon:DocumentTextBoldIcon,
  BoxTimeBoldIcon:BoxTimeBoldIcon,
  BoxTickBoldIcon:BoxTickBoldIcon,
  Note2BoldIcon:Note2BoldIcon,
  BoxBoldIcon:BoxBoldIcon,
  NoteTextBoldIcon:NoteTextBoldIcon,
  CalendarTickBoldIcon:CalendarTickBoldIcon,
  LockCircleBoldIcon:LockCircleBoldIcon,
  House2BoldIcon:House2BoldIcon,
  BuildingBoldIcon:BuildingBoldIcon,
  CardRemoveBoldIcon:CardRemoveBoldIcon,
  BuildingIcon:BuildingIcon,
  House2Icon:House2Icon,
  MoneyReciveIcon:MoneyReciveIcon,
  VerifyBoldIcon:VerifyBoldIcon,
  ForwardSquareBoldIcon:ForwardSquareBoldIcon,
  TagBoldIcon: TagBoldIcon,
  TagLineIcon: TagLineIcon,
} as any;

export default Icon;
