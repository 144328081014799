import axios, { AxiosInstance } from "axios";
import { deleteCookie, getCookie } from "cookies-next";
import https from "https";
import { DateTime } from "luxon";

const httpsAgent = new https.Agent({ rejectUnauthorized: false });

export const createNewClient: () => AxiosInstance = () =>
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/b`,
    headers: {
      "x-api-key": process.env.NEXT_PUBLIC_API_KEY,
      Accept: "application/json",
      "Cache-Control": "max-age=600",
      "x-timezone": DateTime.now().zoneName,
    },
    httpsAgent,
  });

export const client: AxiosInstance = createNewClient();

client.interceptors.request.use(
  (config: any) => {
    const user: any = getCookie("app/session/backoffice", { ...config }) || "";
    if (user) {
      const data = JSON.parse(user);
      if (data && data.token) {
        config.headers.Authorization = `Bearer ${data.token}`;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      deleteCookie("app/session/backoffice");
      deleteCookie("b/profile");
      window.location.href = "/sign-in";
    }
    return Promise.reject(error);
  }
);


type HookType = (accessToken?: string) => AxiosInstance;

const useAxios: HookType = (accessToken) => {
  client.interceptors.request.use((config) => {
    const newConfig: any = { ...config };

    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return newConfig;
  });

  return client;
};

export const { isAxiosError } = axios;

export default useAxios;
