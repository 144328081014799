import { FC, ReactNode } from "react";
import Icon from "../icon/Icon";

interface Props {
  type?: "button" | "submit";
  color?:
    | "outline-primary"
    | "outline-success"
    | "neutral"
    | "primary"
    | "secondary"
    | "danger"
    | "warning"
    | "info"
    | "success"
    | "purple";
  size?: "sm" | "md" | "lg";
  children?: ReactNode;
  className?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  iconColor?: string;
  iconMargin?: string;
  isIconShort?: boolean;
  isLoading?: boolean;
  isOutline?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  form?: string;
  iconSize?: string;
  props?: any;
}

const sizes = {
  sm: "py-2 px-4",
  md: "py-2.5 px-8",
  lg: "py-4 px-8",
};

const sizesWithIcon = {
  left: {
    long: {
      sm: "py-2 px-4",
      md: "py-2.5 px-8",
      lg: "py-4 px-8",
    },
    short: { sm: "py-2 px-4", md: "py-2.5 px-8", lg: "py-4 px-8" },
  },
  right: {
    long: {
      sm: "py-2 px-4",
      md: "py-2.5 px-8",
      lg: "py-4 px-8",
    },
    short: { sm: "py-2 px-4", md: "py-2.5 px-8", lg: "py-4 px-8" },
  },
} as any;

const sizesAttr = {
  sm: "text-[0.875rem] leading-[1rem] rounded-2lg",
  md: "text-[1rem] leading-[1rem] rounded-2lg",
  lg: "text-[1rem] leading-[1rem] rounded-xl",
};

const colors = {
  neutral: "bg-neutral-300 text-neutral-500 cursor-not-allowed",
  primary: "bg-primary-500 hover:bg-primary-600 text-white border",
  secondary:
    "bg-primary-200 hover:bg-primary-300 text-primary-500 hover:text-primary-600",
  danger: "bg-danger-500 hover:bg-danger-600 text-white",
  warning: "bg-warning-500 hover:bg-warning-600 text-white",
  info: "bg-info-500 hover:bg-info-600 text-white",
  success: "bg-green-700 hover:bg-green-600 text-white",
  purple: "bg-purple-500 hover:bg-purple-600 text-white",
  outline:
    "bg-white hover:bg-neutral-100 text-neutral-900 border border-neutral-300",
  "outline-success":
    "bg-white hover:bg-neutral-100 text-green-700 hover:text-green-600 border border-green-700 hover:border-green-600 font-semibold",
  "outline-primary":
    "bg-white hover:bg-neutral-100 text-primary-500 hover:text-primary-600 border border-primary-500 hover:border-primary-600 font-semibold",

};

const iconRange = {
  long: { sm: "gap-[8px]", md: "gap-[24px]", lg: "gap-[24px]" },
  short: { sm: "gap-[4px]", md: "gap-[8px]", lg: "gap-[8px]" },
} as any;

const Button: FC<Props> = ({
  type = "button",
  color = "primary",
  size = "md",
  children,
  className = "",
  icon = "",
  iconPosition = "left",
  iconColor,
  iconMargin,
  isIconShort = false,
  isLoading = false,
  isOutline,
  disabled = false,
  onClick,
  form,
  iconSize,
  props,
}) => {
  let colorClasses = colors[color];
  if (disabled) {
    colorClasses = colors["neutral"];
  } else if (isOutline) {
    colorClasses = colors["outline"];
  }

  let SpesificIcon;
  let sizeClasses = sizes[size];
  let iconType = isIconShort ? "short" : "long";
  if (icon) {
    SpesificIcon = Icon[icon];
    if (children) {
      sizeClasses = sizesWithIcon[iconPosition][iconType][size];
    } else {
      sizeClasses = sizes[size];
    }
  }

  if (isLoading) {
    SpesificIcon = Icon["Loader"];
  }

  let positionClasses = "flex-row";
  if (iconPosition === "right") {
    positionClasses = "flex-row-reverse";
  }

  const sizeAttrClasses = sizesAttr[size];
  const iconRangeClasses = iconRange[iconType][size];

  return (
    <button
      form={form}
      type={type}
      className={`flex ${positionClasses} items-center justify-center font-medium ${colorClasses} ${sizeClasses} ${sizeAttrClasses} ${iconRangeClasses} ${className} relative`}
      disabled={disabled || isLoading}
      onClick={onClick}
      {...props}
    >
      {(icon || isLoading) && (
        <SpesificIcon
          size={size === "sm" ? "16" : iconSize ? iconSize : null}
          className={`${iconMargin} ${iconColor} ${
            color === "neutral" || disabled
              ? "fill-neutral-900"
              : color === "secondary"
              ? "fill-primary-500"
              : "fill-white"
          } ${isLoading && "animate-spin"} ${
            !isLoading && "absolute"
          } md:block ${iconPosition === "left" ? "left-3" : "right-3"}`}
        />
      )}
      {children && !isLoading && (
        <span className={`${isIconShort ? "w-auto" : "w-full"}`}>
          {children}
        </span>
      )}
    </button>
  );
};

export default Button;
