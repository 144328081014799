import { FC, useEffect, useRef, useState } from "react";
import Icon from "../icon/Icon";
import { CloseCircleOutlineIcon } from "../icon/SvgIcon";

interface Props {
  value: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  iconColor?: string;
  onChange?: (e: any) => void;
  onInput?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onBlur?: (e: any) => void;
  onClick?: (e: any) => void;
  required?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  invalidMessage?: string;
  innerRef: any;
  isStar?: boolean;
  readOnly?: boolean;
  withUnit?: string;
  autoComplete?: string;
  onClickIcon?: (e: any) => void;
  onFocus?: (e: any) => void;
  onClear?: (e: any) => void;
  autoFocus?: boolean;
  isIconClick?: boolean;
  hasClear?: boolean;
}

const iconPositionClasses = {
  left: "left-0 pl-4",
  right: "right-0 pr-4",
};

const Input: FC<Props> = ({
  value,
  name,
  type = "text" || "number",
  label = "Text Label",
  icon,
  iconPosition = "left",
  iconColor,
  placeholder = "Input text",
  className = "",
  onChange,
  onInput,
  onKeyUp,
  onBlur,
  onClick,
  required = false,
  isValid = false,
  isInvalid = false,
  invalidMessage = "",
  innerRef,
  isStar,
  readOnly,
  withUnit,
  autoComplete = "on",
  onClickIcon,
  onFocus,
  onClear,
  autoFocus,
  isIconClick = false,
  hasClear = false,
}) => {
  const [isShow, setIsShow] = useState(false);
  let SpesificIcon;
  if (icon) {
    SpesificIcon = Icon[icon];
  } else if (type === "password") {
    if (isShow) {
      SpesificIcon = Icon["EyeSlash"];
    } else {
      SpesificIcon = Icon["Eye"];
    }
  }

  let valid = "";
  if (innerRef.current) {
    if (innerRef.current.value) {
      valid =
        icon && iconPosition === "left"
          ? "valid:pl-12 valid:px-4"
          : "valid:px-4";

      if (withUnit) {
        valid = `${valid} valid:pr-12`;
      }
    }
  }

  return (
    <>
      <div className="relative">
        {icon && (
          <div
            className={`flex absolute inset-y-0 items-center pointer-events-none ${iconColor} ${
              iconPositionClasses[iconPosition]
            } ${isIconClick ? "cursor-pointer" : ""}`}
            onClick={onClickIcon}
          >
            <SpesificIcon />
          </div>
        )}

        {type === "password" && !icon && (
          <div
            className={`flex absolute inset-y-0 items-center cursor-pointer ${iconPositionClasses["right"]}`}
          >
            <SpesificIcon onClick={() => setIsShow(!isShow)} />
          </div>
        )}

        {withUnit && (
          <span className="flex absolute text-xs leading-3 items-center right-3.5 bottom-2">/Miles</span>
        )}

        <input
          ref={innerRef}
          id={`input-${name}`}
          value={value}
          name={name}
          type={type !== "password" ? type : isShow ? "text" : "password"}
          className={`${className} block rounded-lg ${
            icon && iconPosition === "left" ? "pl-12 px-4" : "px-4"
          } pt-5 pb-3 w-full text-regular-none text-neutral-900 bg-white border border-neutral-300 appearance-none focus:pt-5 focus:pb-3 ${valid} focus:outline-none focus:border-neutral-300 focus:ring-0 peer placeholder-transparent ${
            !isValid &&
            isInvalid &&
            invalidMessage &&
            "border-danger-500 focus:border-danger-500"
          }
          ${hasClear && iconPosition === "left" ? '!pr-12' : ''}
          `}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onInput={onInput}
          onClick={onClick}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onFocus={onFocus}
          autoFocus={autoFocus}
        />

        {hasClear && iconPosition === "left" && (
          <div
            className={`flex absolute inset-y-0 items-center cursor-pointer right-[15px] text-neutral-400 hover:text-neutral-600`}
            onClick={onClear}
          >
            <CloseCircleOutlineIcon />
          </div>
        )}

        <label
          htmlFor={`input-${name}`}
          className={`absolute text-regular-none text-neutral-500 duration-300 transform -translate-y-3 scale-75 top-4.5 origin-[0] ${
            icon && iconPosition === "left" ? "left-12" : "left-4"
          } peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 mb-2`}
        >
          {label ?? placeholder}
          {isStar && (
            <span className="text-danger-500"> *</span>
          )}
        </label>
      </div>
      {!isValid && isInvalid && invalidMessage && (
        <p className="mt-2 text-danger-500 text-small-normal">
          {invalidMessage}
        </p>
      )}
    </>
  );
};

export default Input;
