import { GetServerSideProps } from "next";
import Head from "next/head";
import dynamic from "next/dynamic";
import useAxios from "../../app/utils/axios";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useRouter } from "next/router";
import { FC, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { setCookie } from "cookies-next";
import { Validate } from "../../app/helpers/FormValidation";
import ModalAlert from "../../app/helpers/ModalAlert";
import Link from "next/link";
import Logo from "../../app/components/commons/logo/Logo";
import Input from "../../app/components/commons/input/Input";
import Button from "../../app/components/commons/button/Button";
import Checkbox from "../../app/components/commons/checkbox/Checkbox";
import {
  BoxBoldIcon,
  BuildingBoldIcon,
  LogoIcon,
  ShopIcon,
} from "../../app/components/commons/icon/SvgIcon";
import Footer from "../../app/layouts/partials/Footer";

const AuthLayout = dynamic(() => import("../../app/layouts/Auth"));

const ReComponent: FC = () => {
  const axios = useAxios();
  const router = useRouter();

  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha() as any;
  const [recaptchaActionKey, setRecaptchaActionKey] = useState("bakcoffice");

  useEffect(() => {
    if (isSuccess) {
      window.location.href = "/";
    }
  }, [isSuccess, router]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      g_recaptcha: "",
      remember: true,
    },
    validate: (values) =>
      Validate(values, {
        email: "required|email|max:250",
        password: "required|min:5|max:250",
        remember: "boolean",
      }),
    onSubmit: async (value) => {
      formik.setSubmitting(true);
      setLoading(true);

      try {
        const token = await executeRecaptcha(
          `app/session/backoffice${recaptchaActionKey}`
        );

        if (token) {
          value.g_recaptcha = token;
        }

        const { data } = await axios.post("/sign-in", value);
        if (data) {
          setCookie("app/session/backoffice", data, { maxAge: 86400 });
          const { data: lur } = await axios.get("/setting/profile");
          if (lur) {
            setCookie("b/profile", lur, { maxAge: 600 });
            setIsSuccess(true);
          }
        }
      } catch (error: any) {
        ModalAlert({
          title: "Oops!!!",
          message: error?.response?.data.message,
          icon: "error",
        });
      } finally {
        setLoading(false);
      }

      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <Head>
        <title>Sign In</title>
        <meta name="description" content="Let's manage your facility" />
      </Head>
      <AuthLayout bg="bg-blur-backoffice" src="bid" bgColor="primary">
        <div className="md:mx-auto md:px-0 flex flex-col md:flex-row md:space-x-10 space-y-7 md:space-y-0">
          <div className="w-full md:w-96">
            <div className="flex flex-col">
              <Link href="/">
                <a
                  aria-label="Home"
                  className="bg-white rounded-2lg w-11 h-11 flex items-center justify-center "
                >
                  <LogoIcon />
                  {/* <Logo size="44" /> */}
                </a>
              </Link>
              <div className="mt-4.5">
                <h2 className="text-large-normal md:text-title2 font-bold text-neutral-900">
                  Sign In
                </h2>
                <p className="mt-3 font-regular text-small-tight text-neutral-600">
                  Manage your website data
                </p>
              </div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="mt-7 grid grid-cols-1 gap-y-4 sm:grid-cols-2"
            >
              <div className="col-span-full">
                <Input
                  icon="SmsIcon"
                  innerRef={emailRef}
                  value={formik.values.email}
                  name="email"
                  type="email"
                  label="Email Address"
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.email}
                  invalidMessage={formik.errors.email}
                />
              </div>
              <div className="col-span-full">
                <Input
                  icon="KeyIcon"
                  innerRef={passRef}
                  value={formik.values.password}
                  name="password"
                  type="password"
                  label="Password"
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.password}
                  invalidMessage={formik.errors.password}
                />
              </div>
              <div className="col-span-full">
                <Checkbox
                  name="remember"
                  isValid={formik.isValid}
                  isInvalid={formik.touched.remember}
                  invalidMessage={formik.errors.remember}
                  checked={formik.values.remember}
                  onChange={formik.handleChange}
                >
                  Remember this device
                </Checkbox>
              </div>
              <div className="col-span-full">
                <Button
                  type="submit"
                  size="lg"
                  className="w-full"
                  disabled={
                    (!formik.isValid && !!formik.submitCount) || loading
                  }
                  isLoading={loading}
                >
                  Sign In
                </Button>
              </div>
            </form>
          </div>
          <div className="w-full md:w-96">
            <div className="flex flex-col">
              <Link href="/">
                <a
                  aria-label="Home"
                  className="bg-white rounded-2lg w-11 h-11 md:flex items-center justify-center md:invisible hidden"
                >
                  <LogoIcon />
                </a>
              </Link>

              <div className="mt-[1.125rem]">
                <h2 className="text-large-normal md:text-title2 font-bold text-neutral-900">
                  Back Office Web
                </h2>
                <p className="mt-3 text-small-tight text-neutral-600">
                  Control everything your website has
                </p>
              </div>
            </div>
            <div className="mt-7 flex flex-col space-y-[1.563rem] mb-7 md:mb-0">
              <div className="flex flex-row space-x-3">
                <div className="bg-success-500 rounded-full p-2.5 text-white">
                  <ShopIcon />
                </div>
                <div className="flex flex-col space-y-0.5 justify-between">
                  <div className="font-medium text-neutral-900 text-regular-tight">
                    Marketing Website
                  </div>
                  <Link href="https://storageunitauctionlist.com">
                    <a target="_blank" className="text-primary-500 hover:text-primary-600 text-tiny-tight">
                      storageunitauctionlist.com
                    </a>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row space-x-3">
                <div className="bg-primary-500 rounded-full p-2.5 text-white">
                  <BoxBoldIcon />
                </div>
                <div className="flex flex-col space-y-0.5 justify-between">
                  <div className="font-medium text-neutral-900 text-regular-tight">
                    Buyer Website
                  </div>
                  <Link href="https://app.storageunitauctionlist.com/b" target="_blank">
                    <a target="_blank" className="text-primary-500 hover:text-primary-600 text-tiny-tight">
                      app.storageunitauctionlist.com/b
                    </a>
                  </Link>
                </div>
              </div>
              <div className="flex flex-row space-x-3">
                <div className="bg-purple-500 rounded-full p-2.5 text-white">
                  <BuildingBoldIcon />
                </div>
                <div className="flex flex-col space-y-0.5 justify-between">
                  <div className="font-medium text-neutral-900 text-regular-tight">
                    Facility Management Website
                  </div>
                  <Link href="https://app.storageunitauctionlist.com/f" target="_blank">
                    <a target="_blank" className="text-primary-500 hover:text-primary-600 text-tiny-tight">
                      app.storageunitauctionlist.com/f
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </AuthLayout>
    </>
  );
};

const SignIn = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY}
    >
      <ReComponent />
    </GoogleReCaptchaProvider>
  );
};

export default SignIn;
