import Link from "next/link";
import { FC } from "react";
import { MenuFooter } from "../../helpers/MenuList";

type Props = {};

const Footer: FC<Props> = ({}) => {
  return (
    <div className="absolute left-0 bottom-6 md:bottom-[2.125rem] flex justify-center space-x-3 w-full">
      <div className="text-neutral-600 text-small-normal">
        Forgot Password?{" "}
      </div>
      <Link href="/account-recovery">
        <a className="font-bold text-primary-500 hover:text-primary-600 text-small-normal">
          Recover Now
        </a>
      </Link>
    </div>
  );
};

export default Footer;
