import Validator from "validatorjs";

export const Validate = async (data: any, rules: any, msg?: any) => {
  const errors = {} as any;
  let message = {};

  if (msg !== undefined) {
    message = msg;
  }
  const v = new Validator(data, rules, message);

  if (v.fails()) {
    for (const key in data) {
      errors[key] = v.errors.first(key);
    }
  }

  return errors;
};
