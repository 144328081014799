import { FC, ReactNode } from "react";

interface Props {
  children?: ReactNode;
  className?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  invalidMessage?: string;
  id?: string;
  innerRef?: any;
  value?: string | number;
  readOnly?: boolean;
  disabled?: boolean;
}

const Checkbox: FC<Props> = ({
  children,
  className,
  label,
  name,
  checked = false,
  onChange,
  isValid = false,
  isInvalid = false,
  invalidMessage = "",
  id,
  innerRef,
  value,
  readOnly,
  disabled = false,
}) => {
  return (
    <>
      <label htmlFor={id || `checkbox-${name}`}>
        <div className="flex items-center round">
          <input
            ref={innerRef}
            type="checkbox"
            id={id || `checkbox-${name}`}
            name={name || id}
            checked={checked}
            className={`w-6 h-6 text-primary-500 rounded-lg cursor-pointer  appearance-none border border-neutral-500`}
            onChange={onChange}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
          />
          <label htmlFor={id || `checkbox-${name}`} />
          {(children || label) && (
            <div
              className={`ml-2 text-small-normal font-regular text-neutral-600 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              {children || label}
            </div>
          )}
        </div>
      </label>
      {!isValid && isInvalid && invalidMessage && (
        <p className="mt-2 text-danger-500 text-small-normal">
          {invalidMessage}
        </p>
      )}
    </>
  );
};

export default Checkbox;
